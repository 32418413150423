import React from 'react'

const NoMatch = () => {
  return (
    <div>
      NoMatch
    </div>
  )
}

export default NoMatch
